import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import { OrderStatusEnum } from "@/enums";
import orderModel from "@/models/order.model";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import {
  Button,
  Cell,
  CellGroup,
  CountDown,
  Dialog,
  Image,
  Toast,
} from "react-vant";
import styles from "./index.module.css";

export default () => {
  const [_, copy] = useCopyToClipboard();
  const nav = useNavigate();
  const params = useParams();

  const { data, refreshAsync } = useRequest(
    () => {
      return orderModel.detail(params.no!).then((res) => res.data);
    },
    {
      ready: !!params.no,
      refreshDeps: [params.no],
    }
  );
  const expireTime = useMemo(
    () => (data?.expireTime ? dayjs(data?.expireTime).diff(dayjs()) : 0),
    [data?.expireTime]
  );
  return (
    <div className={styles.page}>
      <NavBar title="订单详情" />
      {!data ? (
        <Loader />
      ) : (
        <>
          <div className={styles.header}>
            {OrderStatusEnum.PENDING === data.status && (
              <div className={styles.status}>
                付款倒计时
                <CountDown
                  time={expireTime > 0 ? expireTime : 0}
                  onFinish={async () => {
                    await refreshAsync();
                  }}
                />
              </div>
            )}
            {OrderStatusEnum.COMPLETED === data.status && (
              <div className={styles.status}>
                <Image
                  className={styles.icon}
                  src={require("@/assets/images/order/completed.png").default}
                />
                转入成功
              </div>
            )}
            {OrderStatusEnum.CANCELED === data.status && (
              <div className={styles.status}>
                <Image
                  className={styles.icon}
                  src={require("@/assets/images/order/canceled.png").default}
                />
                订单已取消
              </div>
            )}
            <div className={styles.info}>
              <Image className={styles.image} src={data.asset?.image} />
              <div className={styles.title}>{data.asset?.title}</div>
            </div>
          </div>
          <CellGroup className={styles.container} border={false}>
            <Cell
              title="创建时间"
              value={dayjs(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            />
            <Cell title="购买数量" value={data.num} />
            <Cell title="订单金额" value={`￥${data.total}`} />
            <Cell
              title="订单编号"
              value={<div className={styles.no}>{data.no}</div>}
              onClick={() => {
                copy(data.no);
                Toast({
                  type: "success",
                  message: "复制成功",
                });
              }}
            />
          </CellGroup>
          <div className={styles.footer}>
            {OrderStatusEnum.PENDING === data.status && (
              <>
                <Button
                  className={styles.cancel}
                  block
                  onClick={async () => {
                    await Dialog.confirm({
                      title: "确定要取消订单吗?",
                    });
                    const update = Toast({
                      type: "loading",
                      message: "正在取消...",
                      forbidClick: true,
                      duration: 0,
                    });
                    const res = await orderModel.cancel(data.no);
                    if (res?.code === 200) {
                      await refreshAsync();
                      update.config({
                        type: "success",
                        message: "取消成功",
                        forbidClick: true,
                      });
                    }
                    setTimeout(() => {
                      update.clear();
                    }, 2000);
                  }}
                >
                  取消订单
                </Button>
                <Button
                  className={styles.submit}
                  type="primary"
                  block
                  onClick={() => {
                    if (!data?.payUrl) {
                      Toast("订单信息异常");
                      return;
                    }
                    window.location.href = data?.payUrl;
                  }}
                >
                  去支付
                </Button>
              </>
            )}
            {[OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELED].includes(
              data.status
            ) && (
              <>
                <Button
                  className={styles.cancel}
                  block
                  onClick={() => {
                    nav("/hold");
                  }}
                >
                  我的资产
                </Button>
                <Button
                  className={styles.submit}
                  type="primary"
                  block
                  onClick={() => {
                    nav("/");
                  }}
                >
                  继续购买
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
